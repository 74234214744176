.legal {
  text-align: center;
  margin-inline: 1rem;
}

.legal-body {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  font-size: 1rem;
  margin-top: 1rem;
  max-width: 40rem;
  text-align: justify;
  display: inline-block;
}
