#footer {
  left: 0;
  bottom: 0;
  width: 100%;

  text-align: center;
  font-family: "Quicksand" "san-serif";
  font-weight: 600;
  font-style: normal;
  font-size: 0.9em;
}

.fixed {
  position: fixed;
}
