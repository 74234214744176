body {
  background-color: #f9f9f9;
  color: #303030;
}

h2 {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  font-style: italic;

  font-size: 1.5em;
  margin-bottom: 0;
  margin-top: 10vh;
}

h1 {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  font-size: 4em;
  margin-top: 1em;
}
